import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import {
  getAllFormTemplates,
  getCheckList,
} from "../../lib/services/formService";
import { FormShow } from "./FormShow";
import { useNavigate, useParams } from "react-router-dom";

export const FormList = () => {
  const navigate = useNavigate();
  const [formTemplateList, setFormTemplateList] = useState([]);
  // const [newUser, setNewUser] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formId, setFormId] = useState(null);

  useEffect(() => {
    const fetchCheckList = async () => {
      setLoading(true);
      try {
        const data = await getCheckList();
        console.log("Fetched data:", data);
        setFormTemplateList(data);
        // console.log('formTemplateList', formTemplateList)
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("An error occurred. Awkward..");
      } finally {
        setLoading(false);
      }
    };

    fetchCheckList();
  }, []);

  const handleItemClicked = (id) => {
    setFormId(id);
    // alert('You clicked the third ListGroupItem');
  };

  const handleEditBtnClicked = () => {
    // setFormId(id);
    const formData = { formId: formId };
    navigate('/formEdit', { state: formData });
    // alert('You clicked the third ListGroupItem');
  };

  return (
    <Container className="py-3 ">
      <Stack gap={2}>
        <Row gap={2}>
          <Col sm={10}>
            <h2>Form Builder</h2>
          </Col>
          <Col sm={2} className="d-flex justify-content-end">
            {formId && (
              <Button
                variant="primary"
                onClick={handleEditBtnClicked}
              >
                Edit
              </Button>
            )}
          </Col>
        </Row>

        {loading && <Spinner animation="border" />}
        {error && <p>{error}</p>}
        {/* {console.log(formTemplateList)} */}

        <Row gap={2}>
          <Col>
            <ListGroup className="overflow-auto" style={{ maxHeight: "80vh" }}>
              {formTemplateList.map((item, key) => (
                <ListGroup.Item
                  action
                  href={`#link${key}`}
                  key={key}
                  onClick={() => handleItemClicked(item.formId)}
                >
                  {item.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          <Col sm={9} className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {formId && <FormShow formId={formId} />}
          </Col>
        </Row>
      </Stack>
    </Container>
  );
};
