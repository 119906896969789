import logo from "./logo.svg";
import "./App.css";
import { FormBuild } from "./features/form/FormBuild";
import { FormShow } from "./features/form/FormShow";
import { Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { FormList } from "./features/form/FormList";
import { FormDisplay } from "./features/form/FormDisplay";
import { FormBuildEdit } from "./features/form/FormBuildEdit";


function App() {
  return (
    <>
    <NavBar />
    <Routes>        
      {/* <Route path="/"  element={<Stocks/>} /> */}
      <Route path="/"  element={<FormBuild />}/>
      <Route path="/formList"  element={<FormList />}/>
      {/* <Route path="/formShow" element={<FormShow />} /> */}
      <Route path="/formDisplay/:formId" element={<FormDisplay />} />
      <Route path="/formEdit" element={<FormBuildEdit />} />
    </Routes>
    </>
  );
}

const FormShowWrapper = (props) => {
  const { formId } = props.match.params;
  return <FormShow formId={formId} />;
};

export default App;
