import React, { useEffect, useState } from "react";
import { Form, FormBuilder } from "@formio/react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  FormControl,
  Form as BootForm,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
// import { useLocation, useNavigate } from "react-router-dom";
import { Api } from "../../lib/Api";
import { GetFormData } from "../../lib/GetFormData";
import axios from "axios";
import {
  createCheckList,
  createFormTemplate,
  getFormTemplateById,
  updateFormTemplate,
} from "../../lib/services/formService";
import { useLocation, useNavigate } from "react-router-dom";

export const FormBuildEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formId } = location.state || {};

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const [formTemplate, setFormTemplate] = useState(null);
  const [jsonSchema, setSchema] = useState({ components: [] });

  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // const [formTemplate, setFormTemplate] = useState(null);
  // const [newUser, setNewUser] = useState({ name: '', email: '' });
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFormTemplates = async () => {
      setLoading(true);
      setFormTemplate(null);
      try {
        console.log("formId", formId);
        const data = await getFormTemplateById(formId);
        setSchema(data.schema);
        setInputValue(data.title);
        console.log("schema", data);
        setError(null);
      } catch (error) {
        console.error("Error fetching users:", error.message);
        setError("An error occurred. ", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFormTemplates();
  }, [formId]);

  const onFormChange = (schema) => {
    console.log(schema);
    setSchema({ ...schema, components: [...schema.components] });
  };

  const handleValidation = () => {
    // Add your validation logic here
    if (!inputValue) {
      setErrorMessage("Input field cannot be empty");
    } else if (inputValue.length < 3) {
      setErrorMessage("Input must be at least 3 characters long");
    } else {
      setErrorMessage("");
      // Handle the valid input value
      console.log("Submitted value:", inputValue);
      // You can add further actions here, like sending the data to a server

      handleUpdateFormTemplate(formId, {
        title: inputValue,
        schema: jsonSchema,
      });
    }
  };

  const handleUpdateFormTemplate = async (formId, newFormTemplate) => {
    setLoading(true);
    try {
      const data = await updateFormTemplate(formId, newFormTemplate);
      console.log("Form template updated: ", data);
      const checklist = {
        name: newFormTemplate.title,
        formId: data._id,
        enable: true,
        accountId: 1,
        createdBy: 1,
      };

      navigate("/formList"); //
      // handleCreateChecklist(checklist);
    } catch (error) {
      console.error("Error creating formTemplate:", error);
      setError("Error creating formTemplate");
    } finally {
      setLoading(false);
    }
  };

  // const handleFormChange = (data) => {
  //   const url = "https://dsa.anavadya.io/formTemplates";

  //   axios
  //     .post(url, {
  //       title: "checklist3",
  //       schema: data,
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setLoading(false);
  //       //checking for multiple responses for more flexibility
  //       //with the url we send in.
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       setError("An error occurred. Awkward..");
  //     });
  // };

  return (
    <Container className="py-3">
      <Row className="align-items-center">
        <Col className="">
          <h2>Form Builder</h2>
        </Col>
        <Col className="">
          <FloatingLabel
            controlId="floatingInput"
            label="Form Name"
            className="mb-3"
          >
            <BootForm.Control
              type="text"
              placeholder="test"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              isInvalid={!!errorMessage}
              required
            />
            <BootForm.Control.Feedback type="invalid">
              {errorMessage}
            </BootForm.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>

      <div className="container-fluid">
        <FormBuilder form={jsonSchema} onChange={onFormChange} />
      </div>

      <Row className="d-flex align-items-center my-2">
        <Col className="d-flex justify-content-end">
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <Button
              variant="success"
              // onClick={() => {
              //   // navigate("/formShow", { state:jsonSchema});
              //   // handleFormSubmit(jsonSchema);
              //   console.log('submitted');
              // }}
              onClick={handleValidation}
            >
              Update
            </Button>
          )}
        </Col>
      </Row>
      {/* <Card className="my-4">
        <Card.Body>
          <Card.Title className="text-center">As Rendered Form</Card.Title>
          <Form form={jsonSchema} />
        </Card.Body>
      </Card> */}
    </Container>
  );
};
