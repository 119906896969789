// src/services/userService.js
import axiosInstance from "../axiosConfig";

export const getAllFormTemplates = async () => {
  try {
    const response = await axiosInstance.get('/formTemplates');
    return response.data;
  } catch (error) {
    console.error('Error fetching FormTemplates:', error);
    throw error;
  }
};

export const getFormTemplateById = async (id) => {
    try {
      const response = await axiosInstance.get(`/formTemplates/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching FormTemplateById:', error);
      throw error;
    }
  };

export const createFormTemplate = async (formData) => {
  try {
    const response = await axiosInstance.post('/formTemplates', formData);
    return response.data;
  } catch (error) {
    console.error('Error creating FormTemplate:', error);
    throw error;
  }
};

export const createCheckList = async (data) => {
    try {
      const response = await axiosInstance.post('/checklists', data);
      return response.data;
    } catch (error) {
      console.error('Error creating CheckList:', error);
      throw error;
    }
  };

  export const getCheckList = async () => {
    try {
      const response = await axiosInstance.get('/checklists');
      console.log('CheckList response', response)
      return response.data;
    } catch (error) {
      console.error('Error fetching checklists:', error);
      throw error;
    }
  };

  export const updateFormTemplate = async (formId, formData) => {
    try {
      const response = await axiosInstance.put(`/formTemplates/${formId}`, formData);
      return response.data;
    } catch (error) {
      console.error('Error creating FormTemplate:', error);
      throw error;
    }
  };

// Add other user-related API calls as needed