import React, { useEffect, useState } from "react";
import { Col, Container, Stack } from "react-bootstrap";
import { getFormTemplateById } from "../../lib/services/formService";
import { FormShow } from "./FormShow";
import { useParams } from "react-router-dom";

export const FormDisplay = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formTemplate, setFormTemplate] = useState(null);
    const { formId } = useParams(); // Extract the 'id' parameter from the URL

  return (
    <Container className="py-3 ">
      <Stack gap={2} >
        <h2>Form Builder</h2>

        {/* {loading && <Spinner animation="border" />}
        {error && <p>{error}</p>} */}
        {/* {console.log(formTemplateList)} */}

        <Col className="mx-auto" sm={8} >{formId && <FormShow formId={formId} />}</Col>
      </Stack>
    </Container>
  );
};
